
import { defineComponent, PropType } from 'vue'

export default defineComponent({
    props: {
        value: { type: String, required: true },
        filters: { type: Array as PropType<readonly (string)[]>, required: true },
    },

    computed: {
        selected: {
            get() { return this.filters.indexOf(this.value) },
            set(value: number) { this.$emit('input', this.filters[value]) }
        }
    },
})
