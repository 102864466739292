
import { defineComponent, PropType } from "vue";
import { formatDate } from "./utils/formatDate";
import QNoticesSearchField from "./QNoticesSearchField.vue";
import { Notice, updateFlag } from "@/store/API/PopupAcknowledgements";
import PopupNotice from "@/store/Models/PopupNotice";
import { useSyncProp } from "@/composables/useSyncProp";

export default defineComponent({
	components: { QNoticesSearchField },

	props: {
		items: { type: Array as PropType<Notice[]>, required: true },
		selectedIndex: { type: Number },
		search: { type: String, default: "" },
		title: { type: String, default: "Recent notices" },
		loading: { type: Boolean, default: false },
	},

	emits: ["item-click", "update:search", "update:selectedIndex"],

	setup(props, { emit }) {
		const selectedItemIndex = useSyncProp("selectedIndex", props, emit)

		const onItemClick = (index: number) => {
			emit("item-click");	
			selectedItemIndex.value = index
		}

		return {
			onItemClick,
			selectedItemIndex,
			searchComputed: useSyncProp("search", props, emit),
		};
	},

	methods: {
		formatDate,
		onFavoriteClick(item: Notice) {
			updateFlag(item.id, {
				favorited: !item.favorited,
			}).then((newNotice: Notice) => {
				this.$set(item, "favorited", newNotice.favorited);
			});
		},
	},

	computed: {
		popups() {
			return PopupNotice.state().admin_notices;
		},
	},
});
