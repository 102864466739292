
import {
	computed,
	defineComponent,
	ref,
	toRef,
	watch,
	reactive,
	onUnmounted
} from "vue";
import { getNotices, NoticesGetParams, Notice, ResponseWithPaginationMeta } from '@/store/API/PopupAcknowledgements'
import { debounce } from "vue-debounce"
import { useSelectedItem } from '../../composables/useSelectedItem'
import QNoticesSubmenu from '../../components/navigation/Submenus/QNoticesSubmenu.vue'
import QNoticesSidebar from '../../components/notices/QNoticesSidebar.vue';
import QNoticesCard from '../../components/notices/QNoticesCard.vue';
import QNoticesFilters from '../../components/notices/QNoticesFilters.vue';
import QNoticesAuthorSwitch from '../../components/notices/QNoticesAuthorSwitch.vue';
import { EventBus } from "@/event-bus";

export default defineComponent({
	components: { QNoticesSubmenu, QNoticesSidebar, QNoticesCard, QNoticesFilters, QNoticesAuthorSwitch },

	setup() {
		const isLoading = ref(false)

		// Async Data loading (notices)
		const notices = ref<Notice[]>([])
		const pagination = ref<ResponseWithPaginationMeta | null>(null)

		const filters = reactive<Partial<NoticesGetParams>>({
			search: '',
			author: '',
			snoozed: undefined,
			favorited: undefined,
			archived: undefined,
			acknowledged: undefined,
			page: '1',
		})

		const loadNewNotices = async () => {
			isLoading.value = true
			const { data, meta } = await getNotices(filters, pagination.value)
			notices.value = data
			pagination.value = meta
			isLoading.value = false
		}

		watch(() => pagination, () => {
			filters.page = String(pagination.value?.current_page || 1)
		}, { deep: true })

		watch(() => filters, debounce(async () => {
			return loadNewNotices()
		}, 200), { deep: true })

		// Sidebar
		const { selectedItem, selectedIndex } = useSelectedItem(notices)

		const items = computed(() => notices.value)

		// Filters
		const filterList = [
			'recent messages',
			'favorited',
			'snoozed',
			'archived'
		] as const
		const currentFilter = ref<(typeof filterList)[number]>(filterList[0])

		watch(currentFilter, (newFilter) => {
			if (!newFilter) { currentFilter.value = filterList[0] }
			filters.archived = newFilter === 'archived' ? true : undefined
			filters.favorited = newFilter === 'favorited' ? true : undefined
			filters.snoozed = newFilter === 'snoozed' ? true : undefined

			if (newFilter === 'recent messages') {
				// Hide archived notices from recent
				filters.archived = false
			}
		}, { immediate: true })

		EventBus.$on('update:message', loadNewNotices);
		onUnmounted(() => {
			EventBus.$off('update:message', loadNewNotices);
		})

		// Mobile state

		const messageElement = ref()
		const doShowMessage = ref(false)

		const onSidebarItemSelect = () => {
			doShowMessage.value = true
			window.scrollTo(0, 0)
		}

		return {
			onSidebarItemSelect,
			messageElement,
			doShowMessage,
			isLoading,
			pagination,
			search: toRef(filters, 'search'),
			author: toRef(filters, 'author'),
			currentFilter,
			filterList,
			items,
			selectedIndex,
			selectedItem,
			loadNewNotices,
		}
	},
})
