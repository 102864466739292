import { computed, ref, unref, Ref, watch } from "vue"

/** Accepts array and create synced selectedIndex with selectedItem computed */
export const useSelectedItem = <T>(arr: Ref<T[]>, initialValue = 0) => {
  const selectedIndex = ref(initialValue)

  const selectedItem = computed(() => unref(arr)[selectedIndex.value])

  watch(arr, () => {
    selectedIndex.value = initialValue
  })

  return {
    selectedIndex,
    selectedItem
  }
}
