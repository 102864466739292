<template>
    <QDynamicSubmenu
        :default-title="title"
        :default-buttons="buttons"
        :titlelink="titlelink"
        :sticky="false"
        standard-submenu
    />
</template>

<script>
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QNoticesSubmenu",
    data() {
        return {
            title: "Messages",
            titlelink: [{
                label: "",
                link: "",
            }],
        }
    },
    computed: {
        buttons() {
            const buttons = [{
                label: "Messages Inbox",
                to: { name: 'Notices' },
                icon: "fas fa-arrow-alt-circle-down",
            }]

            if (this.hasRole(['SuperAdmin', 'AgencyOwner', "Exec", 'Staff']) && this.notRole(['SalesRep', 'UnlicensedAgent'])) {
                buttons.push({
                    label: "Sent Messages",
                    to: { name: 'PopupNoticeAdmin' },
                    icon: "fas fa-arrow-alt-circle-down",
                })
            }

            return buttons
        },
    },
    components: {
        QDynamicSubmenu,
    },
}
</script>
