
import { formatDate } from './utils/formatDate'
import { updateFlag, Notice, NoticesFlags } from '@/store/API/PopupAcknowledgements'
import { PropType, defineComponent } from 'vue'
import QNoticesAcknowledgeButtons from './QNoticesAcknowledgeButtons.vue'
import { EventBus } from "@/event-bus";
import { removeFMLToken, parseContentWithMultipleFML } from '../../modules/messages';
import FormWithStepsRenderer from '../../modules/FML/components/FormWithStepsRenderer.vue';
import MessageCardWithFml from '@/modules/messages/components/MessageCardWithFml.vue';
import MessageCardWithoutFml from '@/modules/messages/components/MessageCardWithoutFml.vue';

export default defineComponent({
    components: {
		QNoticesAcknowledgeButtons,
		FormWithStepsRenderer,
		MessageCardWithFml,
		MessageCardWithoutFml,
	},

    props: {
        value: { type: Object as PropType<Notice>, required: true },
    },

    data() {
        return {
			isLoading: false,
			isSaving: false,
			doShowAcknowledgedSnackbar: false,
		}
    },

    computed: {
		isAcknowledged () { return this.value.acknowledged },
		showSnoozeButton () { return !this.value.acknowledged },
		parsedContent () {
			return parseContentWithMultipleFML(this.value.popup.content)
		}
    },

    methods: {
        formatDate,
        removeFMLToken,

        async toggleFlag(flag: keyof NoticesFlags) {
            this.isLoading = true
            const newNotice = await updateFlag(this.value.id, { [flag]: !this.value[flag] })
            this.$set(this.value, flag, newNotice[flag])
            this.isLoading = false
			EventBus.$emit('update:message');
        },

        onArchiveClick() { this.toggleFlag('archived') },

        onFavoriteClick() { this.toggleFlag('favorited') },

	    onSnoozeClick() { this.toggleFlag('snoozed') },

        async archive() {
            await updateFlag(this.value.id, { archived: true })
            this.$set(this.value, 'archived', true)
        },

        async acknowledge() {
			this.isSaving = true

            if (this.parsedContent.fmlSlugs.length) {
                await this.archive()
            }

            await updateFlag(this.value.id, { acknowledged: true })

			this.isSaving = false
		  	EventBus.$emit('update:message');

		  	// TODO: set newNotice.acknowledged, not true
            this.$set(this.value, 'acknowledged', true)
            this.doShowAcknowledgedSnackbar = true
        },
    }
})
