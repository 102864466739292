<template>
    <v-text-field v-model="syncModelValue" :disabled="isDemoMode" placeholder="Search messages">
        <template #prepend>
            <v-icon>
                fas fa-search
            </v-icon>
        </template>       
    </v-text-field>
</template>

<script>
import { useModelValue } from '@/composables/useSyncProp';

export default {
    props: {
        value: { type: String },
    },

    setup(props, { emit }) {
        return {
            syncModelValue: useModelValue(props, emit),
        }
    },
}
</script>