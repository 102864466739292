
  import { defineComponent } from 'vue'
  
  export default defineComponent({
    name: 'QNoticesFilters',
    props: {
      value: { type: String },
    },

    data() {
      return {
        tabValue: 0,
        items: [
          'All',
          'Corporate',
          'Agency',
        ]
      }
    },

    computed: {
      computedValue: {
        get() { return this.tabValue },
        set(v: number) {
          this.tabValue = v
          this.$emit('input', v === 0 ? null : this.items[this.tabValue])
        }
      }
    }
  })
