import { render, staticRenderFns } from "./QNoticesCard.vue?vue&type=template&id=451a7845&scoped=true&"
import script from "./QNoticesCard.vue?vue&type=script&lang=ts&"
export * from "./QNoticesCard.vue?vue&type=script&lang=ts&"
import style0 from "./QNoticesCard.vue?vue&type=style&index=0&id=451a7845&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "451a7845",
  null
  
)

export default component.exports